import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://e80c25d560ea3c1fe570e77e34ac3694@o153781.ingest.us.sentry.io/4507975713685504",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});